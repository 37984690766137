.image-profile{
    width:40px;
    height:40px;
    border-radius:100%;
    border:$border-width solid $gray-600;
    overflow:hidden;
    background-color:$gray-200;

    img{
        width:100%;
        height:auto;
        vertical-align:top;
    }

    &.image-profile-sm{
        width:30px;
        height:30px;
    }

    &.image-profile-md{
        width:60px;
        height:60px;
    }

    &.image-profile-lg{
        width:120px;
        height:120px;
    }
}