@import "~bootstrap/scss/functions";


.large{
    @include font-size($font-size-lg);
    @extend .lh-lg;
}

.text-shadow{
    position:relative;
    z-index:1;

    &:after {
      content: attr(data-text);
      color:red;
      position: absolute;
      left: 0;
      top:0;
      z-index: -1;
      font-size: 1em;
      box-sizing: content-box;
    }
}

@each $name, $color in $theme-colors{
    .text-shadow-#{$name}{
        &:after{
            -webkit-text-stroke-color:$color;
        }
    }
}

.text-shadow-1{
    &:after{
        -webkit-text-stroke-width:2px;
    }
}

.text-shadow-2{
    &:after{
        -webkit-text-stroke-width:4px;
    }
}