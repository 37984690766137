.icon{
    display:inline-block;
    fill:currentColor;

    &.icon-xlg{
        width:40px;
        height:40px;
    }

    &.icon-lg{
        width:30px;
        height:30px;
    }

    &.icon-md{
        width:20px;
        height:20px;
    }

    &.icon-sm{
        width:15px;
        height:15px;
    }

    svg{
        width:inherit;
        height:inherit;
        display:block;
    }
}
