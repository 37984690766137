@import "bootstrap/custom";

$color__stripe-100         :        #69BCD6;
$color__patreon-100        :        #C30000;

body.nonproduct-page, html.nonproduct-page{
    background-color:$color__brand-100;
}

.text-outline{
    -webkit-text-stroke: 2px $gray-600;
    -webkit-text-fill-color: transparent;
}

[anchor]{
    cursor:pointer;
}

#newsletter_response:not(:empty){
    margin-top:10px;

    &.is_error{
        color:$form-feedback-invalid-color;
    }

    &.is_success{
        color:$form-feedback-valid-color;
    }
}

#donate-message:not(:empty){
    color:$form-feedback-valid-color;
    background-color:$white;
    padding:10px;
    margin-bottom:10px;
}

.homepage-section{
    padding:150px 0;
    width:100%;

    h1{
        font-size:76px;
        font-weight:700;
        -webkit-text-stroke: 2px $gray-600;
        -webkit-text-fill-color: transparent;
        text-shadow:4px 4px 0 $color__brand-400;
        position:relative;

        &.h1-alternative{
            font-size:65px;
            font-weight:700;
            -webkit-text-stroke: 2px $gray-600;
            -webkit-text-fill-color: transparent;
            text-shadow:3px 3px 0 $color__brand-400;
            position:relative;
        }

        @include media-breakpoint-down(lg)
        {
            font-size:60px;
        }
    }

    &.alternative{
        background-color:$color__brand-200;
    }

    &.first_section{
        height:calc(100vh - 84px - 20px);
        min-height:600px;
        padding:0;
        display:flex;
    }

    @include media-breakpoint-down(md){
        &.first_section{
            height:auto;
            padding:50px 0;
        }

        padding:50px 0;
    }

    &.homepage-section__no-padding{
        padding:0;
    }
}

.color__green{
    color:$color__brand-500;
}

.progress-bar-text{
    @include media-breakpoint-down(md){
        h2{
            font-size:18px;
            line-height:24px;
        }

        h4{
            font-size:16px;
            line-height:22px;
        }
    }
}

#fundraising-bar{
    width:100%;
    height:40px;
    border:3px solid $gray-600;
    position:relative;

    #fundraising-progress{
        background-color:$color__brand-500;
        position:absolute;
        width:111%;
        left:0;
        top:0;
        height:100%;

        &.start{
            width:0%;
        }

        @include transition(width 0.5s)
    }
}

.transcribe-image{
    width:100%;
    margin-bottom:40px;

    img{
        display:block;
    }

    hr{
        max-width:480px;
        width:100%;
    }
}

#gubo{
    position:absolute;
    left:70px;
    top:-90px;
    height:128px;
}

.homepage-section h1:nth-child(1n){
    animation: animateHeading1 5s infinite;
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
}

.homepage-section.alternative h1:nth-child(1n){
    animation: animateHeading2 5s infinite;
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
}

.credit{
    bottom:0;
    font-size:12px;
    width:100%;
    text-align:center;
}

.image-1-a{
    height:523px;
    position:absolute;
    right:0;
}

.image-1-b{
    width:100%;
}

.image-2-a{
    width:100%;
    position:relative;
}

.image-2-b{
    max-width:564px;
    width:100%;
}

.image-3-a{
    width:100%;
}

.image-4-a{
    width:100%;
}

.image-4-b{
    max-width:100%;
    height:400px;
}

@keyframes animateHeading1 {
  0% {text-shadow: 4px 4px 0 $color__brand-400;}
  25% {text-shadow: #{random(5) - 2.5}px #{random(5) - 2.5}px 0 $color__brand-400; }
  50%   {text-shadow: #{random(5) - 2.5}px #{random(5) - 2.5}px 0 $color__brand-400;}
  75%   {text-shadow: #{random(5) - 2.5}px #{random(5) - 2.5}px 0 $color__brand-400;}
  100% {text-shadow: 4px 4px 0 $color__brand-400;}
}

@keyframes animateHeading2 {
  0% {text-shadow: 4px 4px 0 $color__brand-400;}
  20% {text-shadow: #{random(5) - 2.5}px #{random(5) - 2.5}px 0 $color__brand-400;}
  50%   {text-shadow: #{random(5) - 2.5}px #{random(5) - 2.5}px 0 $color__brand-400;}
  70%   {text-shadow: #{random(5) - 2.5}px #{random(5) - 2.5}px 0 $color__brand-400;}
  100% {text-shadow: 4px 4px 0 $color__brand-400;}
}

.graph-homepage-container{
    display:flex;
    justify-content: center;
    align-items:flex-end;
    height:100%;
    width:100%;
    padding-left:20px;

    $stripe : 4%;
    $comraderyfee : 2.5%;
    $patreonpro: 8%;
    $patreonpremium: 12%;

    .stripe_text, .first_percentage, .second_percentage, .third_percentage{
        font-size:12px;
        border-bottom:2px dashed $gray-600;
        position:absolute;
        bottom:-2px;
        left:0;
        z-index:3;
        text-align:right;
    }

    .stripe_text{
        left:auto;
        right:0;
        text-align:left;
    }

    .homepage-bar{
        width:80px;
        height:500px;
        display:flex;
        flex-wrap:wrap;
        align-items:flex-start;
        position:relative;

        &:not(:last-child){
            margin-right:40px;
        }

        .homepage-bar-stripe{
            display:flex;
            height:$stripe;
            width:100%;
            border:2px solid $gray-600;
            position:absolute;
            background-color:$color__stripe-100;
            opacity:0;
            top:0px;
            z-index:1;
        }

        .homepage-bar-default{
            display:flex;
            position:absolute;
            top:0;
            width:100%;
            height:100%;
            border:2px solid $gray-600;
            background-color:$color__brand-400;
        }

        .homepage-bar-pro, .homepage-bar-premium, .homepage-bar-comradery{
            width:100%;
            border:2px solid $gray-600;
            background-color:$color__patreon-100;
            position:absolute;
            top:#{$stripe};
            opacity:0;
            z-index:1;
        }

        .homepage-bar-pro{
            height:$patreonpro;
        }

        .homepage-bar-premium{
            height:$patreonpremium;
        }

        .homepage-bar-comradery{
            height:$comraderyfee;
        }

        &.graph-premium{
            z-index:1;

            &:after{
                content:"Patreon Premium";
                text-align:center;
                position:absolute;
                width:100px;
                top:100%;
                margin-top:20px;
                font-size:16px;
                font-weight:600;
                left:calc(50% - 50px);
            }
        }

        &.graph-pro{
            z-index:2;

            &:after{
                content:"Patreon Pro";
                text-align:center;
                position:absolute;
                width:100px;
                top:100%;
                margin-top:20px;
                font-size:16px;
                font-weight:600;
                left:calc(50% - 50px);
            }
        }

        &.graph-comradery{
            z-index:3;

            &:after{
                content:"Comradery";
                text-align:center;
                position:absolute;
                width:100px;
                top:100%;
                margin-top:20px;
                font-size:16px;
                font-weight:600;
                left:calc(50% - 50px);
            }
        }

        @include media-breakpoint-down(md){
            width:40px;
            height:350px;

            &:not(:last-child){
                margin-right:25px;
            }

            &.graph-premium{

                &:after{
                    content:"Patreon Premium";
                    width:80px;
                    margin-top:20px;
                    font-size:10px;
                    left:calc(50% - 40px);
                }
            }

            &.graph-pro{

                &:after{
                    content:"Patreon Pro";
                    width:80px;
                    margin-top:20px;
                    font-size:10px;
                    left:calc(50% - 40px);
                }
            }

            &.graph-comradery{

                &:after{
                    content:"Comradery";
                    width:80px;
                    margin-top:20px;
                    font-size:10px;
                    left:calc(50% - 40px);
                }
            }
        }
    }

    &.animate{
        @keyframes move-stripe {
          50%   {opacity:1; top:0;}
          100% {top:-10px; opacity:1;}
       }

       @keyframes adjust-stripe {
         0%   {opacity:1; top:-10px;}
         50%   {opacity:1; top:-10px;}
         100% {opacity:1; top:-17px;}
      }

       @keyframes move-patreon {
         50%   {opacity:1; top:#{$stripe};}
         100% {top:calc(#{$stripe} - 7.9px); opacity:1;}
       }

       @keyframes move-comradery {
         50%   {opacity:1; top:2.9%;}
         100% {top:calc(#{$stripe} - 7.9px); opacity:1;}
       }

       @keyframes remove-stripe {
         49%   {height:100%; top:0;}
         50%   {height:calc(100% - #{$stripe}); top:#{$stripe};}
         100%   {height:calc(100% - #{$stripe}); top:#{$stripe};}
       }

       @keyframes remove-pro {
         0%    {height:calc(100% - #{$stripe}); top:#{$stripe};}
         49%   {height:calc(100% - #{$stripe}); top:#{$stripe};}
         50%   {height:calc(100% - #{$stripe} - #{$patreonpro}); top:calc(#{$stripe} + #{$patreonpro});}
         100%  {height:calc(100% - #{$stripe} - #{$patreonpro}); top:calc(#{$stripe} + #{$patreonpro});}
       }

       @keyframes remove-premium {
         0%    {height:calc(100% - #{$stripe}); top:#{$stripe};}
         49%   {height:calc(100% - #{$stripe}); top:#{$stripe};}
         50%   {height:calc(100% - #{$stripe} - #{$patreonpremium}); top:calc(#{$stripe} + #{$patreonpremium});}
         100%  {height:calc(100% - #{$stripe} - #{$patreonpremium}); top:calc(#{$stripe} + #{$patreonpremium});}
       }

       @keyframes remove-comradery {
         0%    {height:calc(100% - #{$stripe}); top:#{$stripe};}
         49%   {height:calc(100% - #{$stripe}); top:#{$stripe};}
         50%   {height:calc(100% - #{$stripe} - #{$comraderyfee}); top:calc(#{$stripe} + #{$comraderyfee});}
         100%  {height:calc(100% - #{$stripe} - #{$comraderyfee}); top:calc(#{$stripe} + #{$comraderyfee});}
       }

        .homepage-bar-stripe{
            animation-name:move-stripe, adjust-stripe;
            animation-delay:0s, 1.5s;
            animation-duration:1.5s;
            -webkit-animation-fill-mode: forwards; /* Safari 4.0 - 8.0 */
            animation-fill-mode: forwards;
        }

        .homepage-bar-pro, .homepage-bar-premium, .homepage-bar-comradery{
            animation-name:move-patreon;
            animation-delay:1.5s;
            animation-duration:1.5s;
            -webkit-animation-fill-mode: forwards; /* Safari 4.0 - 8.0 */
            animation-fill-mode: forwards;
        }

        .homepage-bar-pro ~.homepage-bar-default{
            animation-name:remove-stripe, remove-pro;
            animation-delay:0s, 1.5s;
            animation-duration:1.5s;
            -webkit-animation-fill-mode: forwards; /* Safari 4.0 - 8.0 */
            animation-fill-mode: forwards;
        }

        .homepage-bar-premium ~.homepage-bar-default{
            animation-name:remove-stripe, remove-premium;
            animation-delay:0s, 1.5s;
            animation-duration:1.5s;
            -webkit-animation-fill-mode: forwards; /* Safari 4.0 - 8.0 */
            animation-fill-mode: forwards;
        }

        .homepage-bar-comradery ~.homepage-bar-default{
            animation-name:remove-stripe, remove-comradery;
            animation-delay:0s, 1.5s;
            animation-duration:1.5s;
            -webkit-animation-fill-mode: forwards; /* Safari 4.0 - 8.0 */
            animation-fill-mode: forwards;
        }
    }

    &.is__static{
        padding-top:calc(#{$stripe} + #{$comraderyfee} + 30px);
        justify-content:flex-end;

        .homepage-bar-default{
            height:calc(100% - #{$stripe} - #{$comraderyfee});
            top:calc(#{$stripe} + #{$comraderyfee});
        }

        .homepage-bar{
            margin-right:60px;
            position:relative;

            .take_text{
                width:250px;
                right:0;
                text-align:left;
                font-size:12px;
                border-bottom:2px dashed $gray-600;
                position:absolute;
                bottom:-2px;
                z-index:3;
                padding-bottom:4px;

                @include media-breakpoint-down(sm){
                    width:200px;
                }

                @include media-breakpoint-down(xs){
                    width:170px;
                }
            }

            &.graph-comradery{
                &:after{
                    content:none;
                }
            }
        }

        .homepage-bar-comradery{
            top:calc(#{$stripe} - 10px);
            opacity:1;

            .first_percentage{
                width:250px;
                right:0;
                left:auto;
                text-align:left;
                padding-bottom:4px;

                @include media-breakpoint-down(md){
                    width:190px;
                }

                @include media-breakpoint-down(sm){
                    width:170px;
                }
            }
        }

        .homepage-bar-stripe{
            opacity:1;
            top:-25px;

            .stripe_text{
                width:250px;
                padding-bottom:4px;

                @include media-breakpoint-down(md){
                    width:190px;
                }

                @include media-breakpoint-down(sm){
                    width:170px;
                }
            }
        }
    }
}

.graph-homepage-line{
    width:75%;
    border-bottom:2px dashed $gray-600;
    bottom:-2px;
    position:absolute;
    right:0;
    font-size:12px;
    padding-bottom:$spacer*0.5;
}

.section-graph-outline{
    border:4px solid $gray-600;
    background-color:transparent;
    position:relative;

    &:after{
        content:"";
        position:absolute;
        z-index:-1;
        left:5px;
        top:5px;
        height:calc(100% + 5px);
        width:calc(100% + 5px);
    }

    &.section-graph-yellow:after{
        background-color:$color__brand-200;
    }

    &.section-graph-blue:after{
        background-color:$color__stripe-100;
    }
}

.homepage-box{
    h3{
        text-shadow:
           -1px -1px 0 $gray-600,
            1px -1px 0 $gray-600,
            -1px 1px 0 $gray-600,
             1px 1px 0 $gray-600;
    }

    h3.first{
        color:$color__brand-200;
    }

    h3.second{
        color:$color__brand-300;
    }

    h3.third{
        color:$color__brand-400;
    }
}

#donate-container{
    visibility: hidden;
}

.list{
    .list__row{
        display:flex;
        flex-wrap:nowrap;

        .list__number{
            font-size:30px;
            font-weight:700;
            padding-right:30px;
        }

        .list__text{
            font-size:18px;
            line-height:27px;
            margin:0;
            padding:0;

            li{
                padding-left:10px;
                margin-left:20px;
            }
        }

        &:not(:last-child){
            margin-bottom:40px;
        }
    }
}

.who-image{
    width:40%;
    border:3px solid $gray-600;

    img{
        width:100%;
        display:block;
    }

    @include media-breakpoint-down(md){
        width:100%;
    }
}

.who-text{
    width:calc(60% - 10px);
    margin-left:10px;

    @include media-breakpoint-down(md){
        width:100%;
        margin-top:10px;
        margin-left:0;
    }
}

.howwe-graphic{
    max-width:208px;
    width:100%;
    margin:auto;

    img{
        width:100%;
    }
}

.committee{
    &:nth-of-type(1){
        color:#AE2A00;
    }

    &:nth-of-type(2){
        color:#D63401;
    }

    &:nth-of-type(3){
        color:#C24804;
    }

    &:nth-of-type(4){
        color:#A06101;
    }

    &:nth-of-type(5){
        color:#866309;
    }
}

#stand-alone-logo{
    height:24px;
    margin-left:20px;
    margin-top:10px;

    img{
        height:100%;
    }
}

#donate-with-paypal{
    background-color:#ffc439; //paypal color so very specific.
    text-decoration:none;
    color:$gray-600;

    img{
        height:21px;
        margin-left:5px;
    }

    &:hover{
        background-color:darken(#ffc439, 5%);
    }

    &:active{
        background-color:darken(#ffc439, 10%);
    }
}

.presskit{
    .element__width-full{
        img{
            max-height:250px;
            max-width:100%;
        }
    }
}

#homepage-beta-banner{
    border:4px dashed #AE2A00;
    background-color:#ffd467;
    padding:20px;
    overflow:hidden;
}