@import "variables.scss";
@import "maps.scss";
@import "~bootstrap/scss/bootstrap";
@import "root.scss";
@import "type.scss";
@import "button.scss";
@import "input-group.scss";
@import "header.scss";
@import "footer.scss";
@import "icons.scss";
@import "image-profile.scss";

.progress-bar.animate-bar.start{
    animation: progressBar 3s ease-out;
    animation-fill-mode:both; 
    
    @keyframes progressBar {
        0% { width: 0; }
        100% { width: 100%; }
    }
}

.logo-project-rotate{
  transform: rotate(270deg);
  transform-origin: bottom right;
  -webkit-transform-origin: bottom right;
  -moz-transform-origin: bottom right;
  writing-mode: vertical-rl;
  float: right;
}

.tier-image{
  width:100%;
  height:124px;
  text-align:center;

  img{
    max-width:100%;
    max-height:100%;
    height:100%;
  }
}

.bg-noise{
  background-image:url('../../images/noise-block.png');
  background-position:center left;
  background-size:375px;
}

@import "bootstrap/scss/utilities/api";
