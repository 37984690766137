.input__icon-container{
    position:relative;

    .icon{
        width:20px;
        height:20px;
        position:absolute;
        pointer-events:none;
        top:calc(50% - 10px);
        margin-left:calc(#{$input-padding-x} + #{$input-border-width});
    }

    input{
        padding-left:calc(#{$input-padding-x} + 20px + 5px);
    }
}

.input__password-container{
    position:relative;

    .password-reveal{
        position:absolute;
        cursor:pointer;
        color:$primary;
        font-weight:$font-weight-bold;
        right:calc(#{$input-padding-x} + #{$input-border-width});
        top:calc(50% - 14px);
    }

    input{
        padding-right:calc(#{$input-padding-x} + 50px);
    }
}

.drag-drop-file{
    width:100%;
    height:200px;
    border:2px dashed $gray-600;
    background-color:$white;
    display:flex;
    align-items:center;
    justify-content:center;
    transition:all 0.1s;
    position:relative;

    input, label{
        display:none;
    }

    &.is-invalid{
        border-color:$red-600;
        color:$red-600;
        position:relative;

        &:before{
            background: transparent escape-svg($form-feedback-icon-invalid) center / contain no-repeat;
            width:30px;
            height:30px;
            content:"";
            color:$red-600;
            position:absolute;
            top:10px;
            right:10px;
        }

        a{
            color:inherit;
        }

        input{

        }
    }

    &.feature-disabled{
        background-color:transparent;
        border:transparent;
        height:auto;
        display:block;

        .dd-content{
            display:none;
        }

        input, label{
            display:block;
        }
    }

    &.image-displayed{
        height:auto;

        .dd-content{
            display:none;
        }
    }

    img{
        max-width:100%;
    }

    &.is-drag-over{
        background-color:rgba($gray-600, 0.2);
    }
}
