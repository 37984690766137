// scss-docs-start theme-colors-map
$theme-colors: (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark,
    "brand-100":  $color__brand-100,
    "brand-200":  $color__brand-200,
    "brand-300":  $color__brand-300,
    "brand-400":  $color__brand-400,
    "brand-500":  $color__brand-500,
    "white"    :  $white,
    "gray-100" :  $gray-100,
    "gray-200" :  $gray-200,
    "gray-300" :  $gray-300,
    "gray-400" :  $gray-400,
    "gray-500" :  $gray-500,
    "gray-600" :  $gray-600,
    "brand-visa": $brand-visa,
    "brand-mastercard" : $brand-mastercard,
    "brand-amex" : $brand-amex,
    "brand-dinersclub" : $brand-dinersclub,
    "brand-discovery" : $brand-discovery,
    "brand-jcb" : $brand-jcb,
);


$spacers: (
  0: 0,
  _5: $spacer * .5,
  1: $spacer,
  2: $spacer * 2,
  3: $spacer * 3,
  4: $spacer * 4,
  5: $spacer * 5,
);

$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
);

$container-max-widths: (
  sm: 470px,
  md: 620px,
  lg: 980px,
  xl: 1120px
);

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $h6-font-size*.65
);

$display-font-sizes: (
  1: 4.375rem,
  2: 3.4375rem,
  3: 2.8125rem,
  4: 2.1875rem,
  5: 1.5625rem,
  6: 1.25rem
);
