#nav-footer{
    background-color:$gray-500;
    padding:50px 0;

    .menu--footer{

        .footer-link{
            padding:0;
            list-style:none;

            .navbar-text{
                @extend h5;
                color:$white;
                margin-bottom:5px;
            }

            ul{
                padding:0;
                list-style:none;

                .footer-text{
                    color:$white;
                    text-decoration:underline;
                }
            }
        }
    }
}

.footer-gap{
    margin-top:200px;
}
