@import "~bootstrap/scss/_functions.scss";

@font-face {
  font-family: Inter;
  src: url("../../fonts/Inter-ExtraLight.woff2") format("woff");
  font-weight: 200;
}

@font-face {
  font-family: Inter;
  src: url("../../fonts/Inter-Light.woff2") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: Inter;
  src: url("../../fonts/Inter-Light.woff2") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: Inter;
  src: url("../../fonts/Inter-Regular.woff2") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: Inter;
  src: url("../../fonts/Inter-Medium.woff2") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  src: url("../../fonts/Inter-SemiBold.woff2") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: Inter;
  src: url("../../fonts/Inter-Bold.woff2") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: Inter;
  src: url("../../fonts/Inter-ExtraBold.woff2") format("woff");
  font-weight: 800;
}

@font-face {
  font-family: Inter;
  src: url("../../fonts/Inter-Black.woff2") format("woff");
  font-weight: 900;
}


// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
$white: #FFFFFF;
$gray-100: #F8F8F8;
$gray-200: #E1E1E1;
$gray-300: #979797;
$gray-400: #656565;
$gray-500: #434343;
$gray-600: #1C1C1C;
$black: $gray-600;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600
);
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$color__brand-100:#FDF5E8;
$color__brand-200:#FFD467;
$color__brand-300:#F17D12;
$color__brand-400:#FF6813;
$color__brand-500:#467D0E;
$cta-primary:  #36610B;
$brand-visa: #1A1F71;
$brand-mastercard: #19110B;
$brand-amex: #2671B9;
$brand-dinersclub: #006699;
$brand-discovery: #E55C20;
$brand-jcb: #E40138;
$green: #198754;
$red: #dc3545;
$yellow: #ffc107;
$blue: #0d6efd;

// scss-docs-start theme-color-variables
$primary:       $cta-primary;
$secondary:     $gray-600;
$success:       $green;
$info:          shade-color($blue, 20%);
$warning:       $yellow;
$danger:        $red;
$light:         $gray-200;
$dark:          $gray-600;

$enable-negative-margins: true;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark:      $gray-600;
$color-contrast-light:     $white;

// scss-docs-start spacer-variables-maps
$spacer: 0.625rem;

$body-bg:                   $white;
$body-color:                $gray-600;


// Links
//
// Style anchor elements.

$link-color:                  $primary;

$grid-columns:                12;
$grid-gutter-width:           1.25rem;
$grid-row-columns:            6;


// stylelint-disable value-keyword-case
$font-family-sans-serif:      'Inter', sans-serif;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;


// $font-size-root effects the value of `rem`, which is used for as well font sizes, paddings and margins
// $font-size-base effects the font size of the body text
$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-md:                $font-size-base * .875;
$font-size-sm:                $font-size-base * .75;
$font-size-lg:                $font-size-base * 1.125;

$font-weight-lighter:         200;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            600;
$font-weight-bolder:          700;

$font-weight-base:            $font-weight-normal;

$line-height-base:            1.75;
$line-height-sm:              1.25;
$line-height-lg:              2;

$h1-font-size:                $font-size-base * 3;
$h2-font-size:                $font-size-base * 2.5;
$h3-font-size:                $font-size-base * 2;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;
// scss-docs-end font-variables

// scss-docs-start headings-variables
$headings-margin-bottom:      $spacer * .5;
$headings-font-family:        null;
$headings-font-style:         null;
$headings-font-weight:        500;
$headings-line-height:        1.15;
$headings-color:              null;
$display-font-weight:         500;
// scss-docs-end headings-variables

$border-style:                solid;
$border-color:                $black;
$border-color-translucent:    rgba($black, 1);

$border-radius:               0;
$border-radius-sm:            0;
$border-radius-lg:            0;

$border-width:                2px;

$input-btn-border-width:      $border-width;

// Buttons
$btn-color:                   $cta-primary;

$btn-font-weight:             $font-weight-bold;
$btn-box-shadow:              2px 0px 0px rgba($gray-600, .075);
$btn-active-box-shadow:       inset 0 3px 5px rgba($gray-600, .125);

$btn-link-disabled-color:     $gray-600;

$box-shadow:                  0.3125rem 0.3125rem $black;

/****************** 

Forms

******************/

//Input variables
$input-font-size-lg:                35px;

$input-bg:                          $white;
$input-disabled-bg:                 $gray-200;

$input-color:                       $body-color;
$input-border-color:                $gray-600;

$input-focus-border-color:          $gray-600;

$input-placeholder-color:           $gray-300;

$input-btn-padding-y-sm:           .25rem;
$input-btn-padding-x-sm:           .5rem;
$input-btn-font-size-sm:           $font-size-sm;

$input-btn-padding-y-lg:           .5rem;
$input-btn-padding-x-lg:           1rem;
$input-btn-font-size-lg:           $font-size-lg;

//Check/Radio variables
$form-check-input-width:            1.75em;

$form-check-input-border:           2px solid $gray-600;
$form-check-input-border-radius:    0;
$form-check-radio-border-radius:    50%;

//Switch variables
$form-switch-width:                 3em;

//Input group variables
$input-group-addon-bg:              $gray-200;

//Select variables
$form-select-disabled-bg:           $gray-200;
$form-select-indicator-color:       $gray-600;


//Range variables
$form-range-track-bg:               $gray-300;

$form-range-thumb-disabled-bg:      $gray-500;

/***************

Navs & Navbar

***************/

//Nav variables
$nav-link-color:                    $gray-600;
$nav-link-disabled-color:           $gray-600;

$nav-tabs-border-color:             $gray-300;
$nav-tabs-link-active-color:        $gray-600;

$nav-pills-link-active-color:       $white;
$nav-pills-link-active-bg:          $gray-600;

//Navbar variables
$navbar-toggler-padding-x:          .25rem;

// scss-docs-start navbar-theme-variables
$navbar-dark-color:                 $color__brand-100;
$navbar-dark-active-color:          $color__brand-100;

$navbar-light-color:                $gray-600;
$navbar-light-hover-color:          rgba($navbar-light-color, .7);
$navbar-light-active-color:         rgba($navbar-light-color, .9);
$navbar-light-disabled-color:       rgba($navbar-light-color, .3);

/***************

Dropdowns

***************/

$dropdown-font-size:                $font-size-md;
$dropdown-bg:                       $white;
$dropdown-border-color:             rgba($gray-600, .15);

$dropdown-link-color:               $gray-600;
$dropdown-link-hover-color:         shade-color($dropdown-link-color, 10%);
$dropdown-link-hover-bg:            $gray-200;

$dropdown-link-disabled-color:      $gray-500;

//Dropdown dark variables
$dropdown-dark-color:               $gray-300;
$dropdown-dark-bg:                  $gray-600;
$dropdown-dark-link-disabled-color: $gray-500;
$dropdown-dark-header-color:        $gray-500;


/***************

Pagination

***************/

$pagination-bg:                     $white;
$pagination-border-color:           $gray-300;

$pagination-focus-bg:               $gray-200;

$pagination-hover-bg:               $gray-200;
$pagination-hover-border-color:     $gray-300;

$pagination-disabled-color:         $gray-600;
$pagination-disabled-bg:            $white;
$pagination-disabled-border-color:  $gray-300;


/***************

Cards

***************/

$card-border-color:                 $gray-600;
$card-cap-bg:                       rgba($card-border-color, .03);
$card-bg:                           $white;

/***************

Accordians

***************/

$accordion-border-color:             rgba($gray-600, .125);

/***************

Tooltips

***************/

$tooltip-color:                     $white;
$tooltip-bg:                        $gray-600;


/***************

Popovers

***************/

$popover-bg:                        $white;
$popover-border-color:              rgba($gray-600, .2);


/***************

Toasts

***************/

$toast-background-color:            rgba($white, .85);

$toast-header-color:                $gray-600;
$toast-header-background-color:     rgba($white, .85);



/***************

Modals

***************/

$modal-inner-padding:               $spacer*2;

$modal-content-bg:                  $white;
$modal-content-border-color:        rgba($gray-600, .2);
$modal-content-border-width:        0;

$modal-backdrop-bg:                 $gray-600;


/***************

Progress bars

***************/

$progress-height:                   5.3125rem;
$progress-font-size:                $font-size-base * .75;
$progress-bg:                       rgba($secondary, .3);
$progress-border-radius:            0;
$progress-box-shadow:               none;
$progress-bar-color:                $white;
$progress-bar-bg:                   $primary;
$progress-bar-animation-timing:     1s linear infinite;
$progress-bar-transition:           width .6s ease;


/***************

List group

***************/

$list-group-hover-bg:               $gray-100;

$list-group-disabled-color:         $gray-600;

$list-group-action-color:           $gray-600;

$list-group-action-active-color:    $body-color;
$list-group-action-active-bg:       $gray-200;


/***************

Image thumbnails

***************/

$thumbnail-border-color:            $gray-300;


/***************

Figures

***************/

$figure-caption-color:              $gray-600;


/***************

Breadcrumbs

***************/

$breadcrumb-divider-color:          $gray-600;
$breadcrumb-active-color:           $gray-600;

/***************

Carousel

***************/

//Dark mode
$carousel-dark-indicator-active-bg:  $gray-600;
$carousel-dark-caption-color:        $gray-600;


/***************

Close

***************/

$btn-close-color:                    $gray-600;